var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("form", [
      _vm._m(0),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Email address")]),
            _c("fg-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.email,
                  expression: "modelValidations.email",
                },
              ],
              attrs: {
                type: "email",
                name: "email",
                error: _vm.getError("email"),
              },
              model: {
                value: _vm.model.email,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "email", $$v)
                },
                expression: "model.email",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Password")]),
            _c("fg-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.password,
                  expression: "modelValidations.password",
                },
              ],
              attrs: {
                type: "password",
                name: "password",
                error: _vm.getError("password"),
              },
              model: {
                value: _vm.model.password,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "password", $$v)
                },
                expression: "model.password",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Confirm Password")]),
            _c("fg-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.confirmPassword,
                  expression: "modelValidations.confirmPassword",
                },
              ],
              attrs: {
                type: "password",
                name: "confirm",
                error: _vm.getError("confirm"),
              },
              model: {
                value: _vm.model.confirmPassword,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "confirmPassword", $$v)
                },
                expression: "model.confirmPassword",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "card-footer text-right" },
        [
          _c("p-checkbox", { staticClass: "pull-left" }, [
            _vm._v("Subscribe to newsletter"),
          ]),
          _c(
            "p-button",
            {
              attrs: { type: "info" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.validate.apply(null, arguments)
                },
              },
            },
            [_vm._v("Register")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [
        _vm._v("\n        Register Form\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
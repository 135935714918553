var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { staticClass: "form-horizontal" }, [
    _c(
      "h4",
      { staticClass: "card-title", attrs: { slot: "header" }, slot: "header" },
      [_vm._v("\n    Type Validation\n  ")]
    ),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-sm-2 col-form-label" }, [
        _vm._v("Required Text"),
      ]),
      _c(
        "div",
        { staticClass: "col-sm-7" },
        [
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.requiredText,
                expression: "modelValidations.requiredText",
              },
            ],
            attrs: {
              type: "text",
              name: "requiredText",
              error: _vm.getError("requiredText"),
            },
            model: {
              value: _vm.model.requiredText,
              callback: function ($$v) {
                _vm.$set(_vm.model, "requiredText", $$v)
              },
              expression: "model.requiredText",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-sm-3 label-on-right" }, [
        _c("code", [_vm._v("required:true")]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-sm-2 col-form-label" }, [
        _vm._v("Email"),
      ]),
      _c(
        "div",
        { staticClass: "col-sm-7" },
        [
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.email,
                expression: "modelValidations.email",
              },
            ],
            attrs: {
              type: "text",
              name: "email",
              error: _vm.getError("email"),
            },
            model: {
              value: _vm.model.email,
              callback: function ($$v) {
                _vm.$set(_vm.model, "email", $$v)
              },
              expression: "model.email",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-sm-3 label-on-right" }, [
        _c("code", [_vm._v("email:true")]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-sm-2 col-form-label" }, [
        _vm._v("Number"),
      ]),
      _c(
        "div",
        { staticClass: "col-sm-7" },
        [
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.number,
                expression: "modelValidations.number",
              },
            ],
            attrs: {
              type: "text",
              name: "number",
              error: _vm.getError("number"),
            },
            model: {
              value: _vm.model.number,
              callback: function ($$v) {
                _vm.$set(_vm.model, "number", $$v)
              },
              expression: "model.number",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-sm-3 label-on-right" }, [
        _c("code", [_vm._v("number:true")]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-sm-2 col-form-label" }, [_vm._v("Url")]),
      _c(
        "div",
        { staticClass: "col-sm-7" },
        [
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.url,
                expression: "modelValidations.url",
              },
            ],
            attrs: { type: "text", name: "url", error: _vm.getError("url") },
            model: {
              value: _vm.model.url,
              callback: function ($$v) {
                _vm.$set(_vm.model, "url", $$v)
              },
              expression: "model.url",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-sm-3 label-on-right" }, [
        _c("code", [_vm._v("url:true")]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-sm-2 col-form-label" }, [
        _vm._v("Equal to"),
      ]),
      _c(
        "div",
        { staticClass: "col-sm-3" },
        [
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.idSource,
                expression: "modelValidations.idSource",
              },
            ],
            attrs: {
              type: "text",
              name: "idSource",
              placeholder: "#idSource",
              error: _vm.getError("idSource"),
            },
            model: {
              value: _vm.model.idSource,
              callback: function ($$v) {
                _vm.$set(_vm.model, "idSource", $$v)
              },
              expression: "model.idSource",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-3" },
        [
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.idDestination,
                expression: "modelValidations.idDestination",
              },
            ],
            attrs: {
              type: "text",
              name: "idDestination",
              placeholder: "#idDestination",
              error: _vm.getError("idDestination"),
            },
            model: {
              value: _vm.model.idDestination,
              callback: function ($$v) {
                _vm.$set(_vm.model, "idDestination", $$v)
              },
              expression: "model.idDestination",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-sm-3 label-on-right" }, [
        _c("code", [_vm._v("confirmed: 'idSource'")]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "text-center", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "p-button",
          {
            attrs: { type: "info" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.validate.apply(null, arguments)
              },
            },
          },
          [_vm._v("Validate inputs")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }